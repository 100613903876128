<template>
    <div>
        <div id="lesseeManage">
            <div class="mainTable">
                <TableWrap @callback="handlePagination" :total="total">
                    <template slot="tablesearch">
                        <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
                    </template>
                    <template slot="tableOperate">
                        <el-button type="primary" size="small" @click="handleAdd">
                            新增
                        </el-button>
                        <el-button type="primary" size="small" @click="handleEdit">
                            修改
                        </el-button>
                        <el-button type="danger" size="small" @click="handleDel">
                            删除
                        </el-button>
                    </template>
                    <el-table :data="tableData"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{background:'#FAFAFA'}"
                        v-loading="loading"
                        ref="lmMultipleTable">
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column prop="status" label="状态" :formatter="formatter" width="60">
                        </el-table-column>
                        <el-table-column prop="code" label="租户编码" width="160">
                        </el-table-column>
                        <el-table-column prop="name" label="租户名称" width="160">
                        </el-table-column>
                        <el-table-column prop="taxCerNum" label="纳税人识别号" width="160">
                        </el-table-column>
                        <el-table-column prop="industryCategory" label="行业分类">
                        </el-table-column>
                        <el-table-column prop="businessRegistrationTime" label="工商注册时间" width="160">
                        </el-table-column>
                        <el-table-column prop="registrationType" label="注册类型">
                        </el-table-column>
                        <el-table-column prop="parentId" label="父级租户">
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="160">
                        </el-table-column>
                        <el-table-column prop="updateTime" label="修改时间" width="160"></el-table-column>
                        <el-table-column  fixed="right" label="操作" width="150">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="viewDetails(scope.row)">
                                    详情
                                </el-button>
                                <el-button type="text" size="small" @click="whetherLock(scope.row)">
                                    <span v-if="scope.row.status === 0" style="color:#F5222D">
                                        冻结
                                    </span>
                                    <span v-else>
                                        解冻
                                    </span>
                                </el-button>
                                <el-button type="text" size="small" @click="shouquan(scope.row)">
                                    <span  v-if="scope.row.publicKey === '' || scope.row.publicKey === null" style="color:#F5222D">
                                        授权
                                    </span>
                                    <span v-else>
                                        重新授权
                                    </span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </TableWrap>
            </div>
             <!-- 授权弹窗 -->
         <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose">
        <el-input id="text" type="textarea" v-model="publicKey1"/>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subShouquan">确 定</el-button>
      </span>
      </el-dialog>
        </div>
        <!-- 弹出表单 -->
        <PubForm ref="refForm" @callback="handleCallback" :curFormDrawerTitle="formDrawerTitle" :curFormDrawer="formDrawer" :curRowData="curRowData" @formDrawerVisible="formDrawerVisible" />
    </div>
</template>
<script>
import PubForm from './PubForm'
import tableMixin from '@/mixIn/tableMixIn'
import { reqLessee, reqLesseeDetails, reqLesseeDelete, reqLockLessee, reqUnlockLessee, reqLicenseById } from '@/api/platform/getLesseeInfo'

export default {
    /* 租户管理界面 */
    name: 'LesseeManage',

    components: {
        PubForm
    },

    mixins: [tableMixin],

    data () {
        return {
            searchOptions: [
                { type: 'input', label: '租户名称', key: 'searchValue' }
            ],
            reqTableData: reqLessee,
            formDrawerTitle: '', // 弹出抽屉表单组件的标题
            formDrawer: false, // 是否弹出表单
            curRowData: {}, // 当前行数据
            // curRowDtl: {}, // 当前行详情数据
            dicItem: [], // 字典项信息
            statusRow: [],
            tenantStatus: [], // 存放租户状态
            title: '', // 弹出框标题
            dialogVisible: false,
            currentId: '',
            obj: {
                publicKey: ''
            },
            publicKey1: ''
        }
    },

    created () {
        this.$utils.getDicData('TENANT_STATUS').then((res) => {
            this.tenantStatus = res
            // 请求表格数据
            this.getTableData()
        })
    },

    methods: {

        // 根据后台请求，显示租户状态
        formatter (row, column) {
            return this.$utils.getDicValue('TENANT_STATUS', row.status).mean
        },

        // 子组件传回来的回调
        handleCallback () {
            this.getTableData()
        },

        // 点击新增按钮
        handleAdd () {
            this.formDrawerTitle = '新增'
            this.formDrawer = true
        },

        // 点击修改按钮
        handleEdit () {
            const len = this.multipleSelection.length
            if (len === 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选择您要修改的某行数据！'
                })
            } else if (len > 1) {
                this.$message({
                    type: 'warning',
                    message: '修改表格只能选中一行喔！'
                })
                this.$refs.lmMultipleTable.clearSelection()
            } else {
                const id = this.multipleSelection[0].id
                this.formDrawerTitle = '修改'
                reqLesseeDetails({
                    id
                }).then((result) => {
                    this.curRowData = result.data
                })
                this.formDrawer = true
            }
        },

        // 点击删除按钮
        handleDel () {
            const curSel = this.multipleSelection
            const len = curSel.length
            if (len === 1) {
                this.$confirm('确认删除吗？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    reqLesseeDelete({
                        id: curSel[0].id
                    }).then((result) => {
                        this.getTableData()
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        })
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除！'
                    })
                })
            } else if (len > 1) {
                this.$message.error('请选择一行数据删除！')
                this.$refs.lmMultipleTable.clearSelection()
            } else {
                this.$message.error('请先选择一行数据！')
            }
        },

        // 点击详情按钮
        viewDetails (row) {
            this.formDrawerTitle = '详情'
            this.formDrawer = true
            reqLesseeDetails({
                id: row.id
            }).then((result) => {
                this.curRowData = result.data
            })
        },

        // 子组件传递回来的自定义事件，告知父组件弹出表单开还是关
        formDrawerVisible (msg) {
            this.formDrawer = msg
        },

        // 解冻与冻结租户
        whetherLock (row) {
            if (row.status === 0) {
                reqLockLessee({
                    id: row.id
                }).then((res) => {
                    row.status = 1
                })
            } else {
                reqUnlockLessee({
                    id: row.id
                }).then((res) => {
                    row.status = 0
                })
            }
        },
        /** 查询条件 */
        handleSearchCallback ({ data }) {
            this.query = data
            this.getTableData()
        },
        // 点击授权操作
        shouquan (row) {
            // console.log(1111)
            this.currentId = row.id
            if (row.publicKey === '' || row.publicKey === null) {
                this.publicKey1 = ''
                this.dialogVisible = true
                this.title = 'license授权'
            } else {
                this.dialogVisible = true
                this.title = '重新授权'
                this.publicKey1 = row.publicKey
            }
        },
        handleClose () {
            this.dialogVisible = false
        },
        subShouquan () {
            const id = Number(this.currentId)
            this.obj.publicKey = this.publicKey1
            // console.log(this.obj)
            reqLicenseById(id, this.obj).then((res) => {
                // console.log(res)
                if (res.code === 0) {
                    this.$message.success('操作成功')
                    this.dialogVisible = false
                    this.publicKey1 = ''
                    this.getTableData()
                    this.obj.publicKey = ''
                }
            })
        }
    }
}
</script>
<style lang="scss">
#lesseeManage {
  .el-dialog .el-textarea .el-textarea__inner {
  height: 160px !important;
}
#text {
  height: 160px !important;
}
}
</style>
