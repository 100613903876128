<template>
    <div>
        <el-drawer
            :title="formDrawerTitle"
            :visible.sync="formDrawer"
            :direction="formShowDirection"
            :wrapperClosable="false"
            @open="handleOpen"
            custom-class="cus-drawer"
            @close="handleClose">
            <div class="cus_drawer_content">
                <el-form ref="ruleLesseeForm" :model="pubLesseeForm" :rules="rules" :disabled="isAble">
                    <div class="pageHeader">
                        <p>基础信息</p>
                    </div>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="租户名称" prop="name">
                                <el-input size="small" v-model="pubLesseeForm.name" placeholder="请输入租户名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="租户编码" prop="code">
                                <el-input size="small" v-model="pubLesseeForm.code" placeholder="请输入租户编码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="联系人" prop="contacter">
                                <el-input size="small" v-model="pubLesseeForm.contacter" placeholder="请输入联系人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系人电话" prop="contacterPhone">
                                <el-input size="small" v-model="pubLesseeForm.contacterPhone" placeholder="请输入联系人电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="邮箱" prop="email">
                                <el-input size="small" v-model="pubLesseeForm.email" placeholder="请输入邮箱"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="主要负责人" prop="mainInCharge">
                                <el-input size="small" v-model="pubLesseeForm.mainInCharge" placeholder="请输入主要负责人"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="父级租户" prop="parentId">
                                <el-select v-model="pubLesseeForm.parentId" placeholder="请选择父级租户" size="small" style="width:100%;">
                                    <el-option v-for="plItem in plOptions" :key="plItem.id" :label="plItem.name" :value="plItem.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="管理部门" prop="parentDeptId">
                                <el-cascader size="small" v-model="pubLesseeForm.parentDeptId" ref="deptCascader" :options="deptOptions" :props="deptProps" :show-all-levels="false"  @change="getDeptCheckedNodes" style="width:100%;"></el-cascader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15" v-show="showOrHide">
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <el-date-picker size="small" v-model="pubLesseeForm.createTime" type="datetime" style="width:100%"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="修改时间">
                                <el-date-picker size="small" v-model="pubLesseeForm.updateTime" type="datetime" style="width:100%"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="关联应用组" prop="rltAppGroup">
                                <el-select v-model="pubLesseeForm.rltAppGroup" multiple placeholder="请选择关联应用组（可多选）" size="small" style="width:100%;">
                                    <el-option v-for="ragItem in ragOptions" :key="ragItem.id" :label="ragItem.name" :value="ragItem.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="状态" prop="" v-show="showStatus">
                                    <el-input size="small" v-model="pubLesseeForm.status"></el-input>
                                <!-- <el-select v-model="pubLesseeForm.status" placeholder="请选择状态"> -->
                                    <!-- <el-option label="0" value="0"></el-option>
                                    <el-option label="1" value="1"></el-option> -->
                                    <!-- <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
                                <!-- </el-select> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="pageHeader">
                        <p>工商信息</p>
                    </div>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="工商注册时间" prop="businessRegistrationTime">
                                <el-date-picker size="small" type="date" v-model="pubLesseeForm.businessRegistrationTime" placeholder="请选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width:100%;"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="注册类型" prop="registrationType">
                                <el-input size="small" v-model="pubLesseeForm.registrationType" placeholder="请输入注册类型"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="纳税人识别号" prop="taxCerNum">
                                <el-input size="small" v-model="pubLesseeForm.taxCerNum" placeholder="请输入纳税人识别号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="主营业务">
                                <el-input size="small" v-model="pubLesseeForm.mainBusiness" placeholder="请输入主营业务"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="pageHeader">
                        <p>其他</p>
                    </div>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="行业分类">
                                <el-input size="small" v-model="pubLesseeForm.industryCategory" placeholder="请输入行业分类"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否独立法人">
                                <el-select size="small" v-model="pubLesseeForm.isindependentLegal" placeholder="请选择独立法人" style="width:100%;">
                                    <el-option label="是" value="1"></el-option>
                                    <el-option label="否" value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="企业人数" prop="">
                                <el-input size="small" v-model="pubLesseeForm.numberBusinesses" placeholder="请输入企业人数"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="分管安全领导" prop="">
                                <el-input size="small" v-model="pubLesseeForm.safetyLeadership" placeholder="请输入分管安全领导"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="15">
                        <el-col :span="12">
                            <el-form-item label="经度" prop="">
                                <el-input size="small" v-model="pubLesseeForm.lng" placeholder="请输入经度"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="纬度" prop="">
                                <el-input size="small" v-model="pubLesseeForm.lat" placeholder="请输入纬度"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="营业执照照片" v-show="!isAble">
                                <!-- <UploadImgWrap @toParent="uploadImg" :imgUrls="imgUrls"/> -->
                            </el-form-item>
                            <el-form-item label="营业执照照片 :" v-show="isAble">
                                <ul>
                                    <li v-for="(item) in imgUrls" :key="item.id" style="display:inline-block;border: 1px solid #ccc;margin: 5px;">
                                        <img style="width:146px;height:146px;" :src="item.fileUrl" alt="">
                                    </li>
                                </ul>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="cus_drawer_footer">
                    <el-button size="small" plain @click="handleClose">
                        取消
                    </el-button>
                    <el-button type="primary" v-if="!isAble" size="small" @click="submitForm('ruleLesseeForm')">
                        提交
                    </el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { reqTenantTree, reqLesseeEdit, reqLesseeAdd, reqLessee, reqRelAppGroup } from '@/api/platform/getLesseeInfo'
// import UploadImgWrap from '@/components/uploadImgWrap'

export default {
    /* 租户管理 - 弹出表单 */
    name: 'PubForm',

    components: {
        // UploadImgWrap
    },

    data () {
        const validatePhone = (rule, value, callback) => {
            if (value !== '') {
                if (!this.$utils.validateMobilePhone(value)) {
                    callback(new Error('请输入正确的手机号码！'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        // const validateCode = (rule, value, callback) => {
        //     console.log(value)
        //     const codeReg = /^[0-9]+$/
        //     if (value === '') {
        //         callback(new Error('租户编码不能空！'))
        //     } else {
        //         if (codeReg.test(value)) {
        //             callback(new Error('租户编码不能为纯数字！'))
        //         } else {
        //             callback()
        //         }
        //         callback()
        //     }
        // }

        return {
            selLoad: true,
            formDrawerTitle: '',
            formDrawer: false,
            formShowDirection: 'rtl',
            deptOptions: [], // 管理部门集数据源
            imgUrls: [], // 图片回填用到的
            deptProps: { label: 'name', value: 'id', checkStrictly: true }, // 部门配置选项
            pubLesseeForm: {
                id: '', // 租户id
                name: '', // 租户名称
                code: '', // 租户编码
                contacter: '', // 联系人
                contacterPhone: '', // 联系人电话
                email: '', // 邮箱
                numberBusinesses: '', // 企业人数
                parentId: [], // 父级租户
                mainInCharge: '', // 主要负责人
                lng: '', // 经度
                lat: '', // 纬度
                parentDeptId: '', // 管理部门
                safetyLeadership: '', // 分管安全领导
                rltAppGroup: [],
                status: '', // 状态
                businessRegistrationTime: '', // 工商注册时间
                registrationType: '', // 注册类型
                mainBusiness: '', // 主营业务
                isindependentLegal: '', // 是否独立法人
                industryCategory: '', // 行业分类
                taxCerNum: '', // 纳税人识别号
                busiCerPhoto: '', // 营业执照照片
                createTime: '', // 创建时间
                updateTime: '' // 修改时间
            },
            plOptions: [],
            ragOptions: [],
            rules: {
                name: { required: true, message: '请输入租户名称', trigger: 'blur' },
                code: { required: true, message: '请输入租户编码', trigger: 'blur' },
                contacter: { required: true, message: '请输入联系人', trigger: 'blur' },
                contacterPhone: { required: true, validator: validatePhone, trigger: 'blur' },
                mainInCharge: { required: true, message: '请输入负责人', trigger: 'blur' },
                // parentId: { required: true, message: '请输入父级租户', trigger: 'blur' },
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                rltAppGroup: { required: true, message: '请选择关联应用组', trigger: 'change' },
                // parentDeptId: { required: true, message: '请选择管理部门', trigger: 'change' },
                // taxCerNum: { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
                // registrationType: { required: true, message: '请输入注册类型', trigger: 'blur' },
                // businessRegistrationTime: { required: true, message: '请选择工商注册时间', trigger: 'change' },

            },
            // buzLicImgUrl: [],
            isAble: false,
            imgFile: '',
            imgFileList: '',
            showOrHide: true,
            initialForm: {},
            showStatus: false, // 状态显示与隐藏
            tenantStatus: [],
            // accordion: false // 其他信息是否展开
            current: 1,
            query: {

            }
        }
    },
    props: {
        curFormDrawerTitle: {
            type: String,
            default: ''
        },
        curFormDrawer: { type: Boolean, default: false },
        curRowData: {
            type: Object,
            default: () => {}
        },
        // curRowDtl: {
        //     type: Object,
        //     default: () => {}
        // }
    },
    created () {
        // 管理部门
        this.getTenantDeptTree()
        this.initialForm = this.pubLesseeForm
        this.getParentLessee()
        // console.log(this.query)
        try {
            this.query.current = this.current
            this.query.size = 200
        } catch (error) {
            console.log(error)
        }

        reqRelAppGroup({ ...this.query }).then((res) => {
            res.data.records.forEach(e => {
                this.ragOptions.push({
                    id: e.id,
                    name: e.name
                })
            })
        })
        this.$utils.getDicData('TENANT_STATUS').then((res) => {
            this.tenantStatus = res
        })
    },

    watch: {
        curFormDrawerTitle: {
            handler (newVal) {
                this.formDrawerTitle = newVal
                if (this.formDrawerTitle === '详情') {
                    this.isAble = true
                    this.showOrHide = true
                    this.showStatus = true
                } else {
                    this.isAble = false
                    this.showOrHide = false
                    this.showStatus = false
                }
                if (this.formDrawerTitle === '新增') {
                    this.pubLesseeForm = {}
                    // this.pubLesseeForm = this.initialForm
                    this.pubLesseeForm.status = 0
                    this.pubLesseeForm.status = this.$utils.getDicValue('TENANT_STATUS', this.pubLesseeForm.status).mean
                } else {
                    this.pubLesseeForm = this.initialForm
                }
            }
        },
        curFormDrawer (newVal) {
            this.formDrawer = newVal
        },
        curRowData: {
            handler (newVal) {
                if (this.formDrawerTitle === '修改' || this.formDrawerTitle === '详情') {
                    const cur = { ...newVal }
                    if (cur) {
                        cur.appgroupIds = cur.appgroupIds.split(',')
                        cur.tenant.rltAppGroup = cur.appgroupIds.map(Number)
                        Object.keys(cur.tenant).forEach(v => {
                            this.pubLesseeForm[v] = cur.tenant[v]
                        })
                        this.pubLesseeForm.code = cur.tenant.code
                        this.pubLesseeForm.status = this.$utils.getDicValue('TENANT_STATUS', cur.tenant.status).mean

                        // 图片回填
                        if (cur.tenant.busiCerPhoto) {
                            this.imgUrls = JSON.parse(cur.tenant.busiCerPhoto)
                        }
                    }
                } else {
                    this.pubLesseeForm = {}
                }
            }
        },
    },
    methods: {
        // 获取父级租户
        getParentLessee () {
            reqLessee({
                size: 10000
            }).then((res) => {
                res.data.records.forEach(e => {
                    this.plOptions.push({ id: e.id, name: e.name })
                })
            })
        },
        // 获取租户部门树 - 管理部门数据
        getTenantDeptTree () { reqTenantTree().then((res) => { this.deptOptions[0] = res.data }) },

        // 上传图片
        uploadImg (res) {
            // console.log(res)
            // this.buzLicImgUrl = res
            // this.pubLesseeForm.busiCerPhoto = res[0].url
            this.pubLesseeForm.busiCerPhoto = res.map((item) => {
                if (item.response) {
                    return {
                        fileUrl: item.response.data.objectUrl,
                        fileName: item.name,
                    }
                } else {
                    return {
                        fileUrl: item.url,
                        fileName: item.name
                    }
                }
            })
            this.pubLesseeForm.busiCerPhoto = JSON.stringify(this.pubLesseeForm.busiCerPhoto)
            // console.log()
        },
        /** 部门选中 */
        getDeptCheckedNodes (val) {
            const deptNodesObj = this.$refs.deptCascader.getCheckedNodes()
            if (deptNodesObj.length > 0) {
                this.pubLesseeForm.parentDeptId = deptNodesObj[0].data.id // 部门id
                // this.form.deptName = deptNodesObj[0].data.label // 部门名称
            } else {
                this.pubLesseeForm.parentDeptId = '' // 部门id
                // this.form.deptName = '' // 部门名称
            }
        },
        // 提交
        submitForm (formName) {
            const formData = Object.assign({}, this.pubLesseeForm)
            const curForm = Object.assign({}, this.pubLesseeForm)
            const ids = curForm.rltAppGroup.join()
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formDrawerTitle === '新增') {
                        const params = {}
                        params.appgroupIds = ids
                        params.tenant = formData
                        params.tenant.status = 0
                        delete params.tenant.rltAppGroup
                        reqLesseeAdd(params).then((result) => {
                            result.data = params
                            this.formDrawer = false
                            this.$emit('formDrawerVisible', this.formDrawer)
                            this.$message({
                                type: 'success',
                                message: '新增成功！'
                            })
                            this.$emit('callback')
                            this.$refs[formName].resetFields()
                            this.getParentLessee()
                        })
                    }

                    if (this.formDrawerTitle === '修改') {
                        const params = {}
                        params.appgroupIds = ids
                        params.tenant = formData
                        if (formData.status === '正常') {
                            params.tenant.status = 0
                        } else {
                            params.tenant.status = 1
                        }
                        params.tenant.code = formData.code
                        params.tenant.updateTime = null
                        delete params.tenant.rltAppGroup
                        reqLesseeEdit(params).then((result) => {
                            result.data = params
                            this.formDrawer = false
                            this.$message({
                                type: 'success',
                                message: '修改成功！'
                            })
                            this.$emit('callback')
                            this.getParentLessee()
                        })
                    }

                    if (this.formDrawerTitle === '详情') {
                        this.formDrawer = false
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '打 * 号的选项不能为空!'
                    })
                }
            })
        },
        handleOpen () {
            if (this.formDrawerTitle === '新增') {
                this.$nextTick(() => {
                    this.$refs.ruleLesseeForm.clearValidate()
                })
            }
        },
        handleClose () {
            this.formDrawer = false
            this.$emit('formDrawerVisible', this.formDrawer)
            this.resetForm('ruleLesseeForm')
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
