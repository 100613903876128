import request from '@/api/request'

// 租户管理接口
/* 获取租户列表 */
export function reqLessee (params) { return request({ url: '/tenant/page', params: params }) }

/* 获取租户详情，通过ID查询租户 */
export function reqLesseeDetails (params) { return request({ url: '/tenant/:id', params }) }

/* 修改租户 */
export function reqLesseeEdit (params) { return request({ url: '/tenant', params, method: 'PUT' }) }

/* 添加租户 */
export function reqLesseeAdd (params) { return request({ url: '/tenant', params, method: 'post' }) }

// 获取管理部门 查询租户部门树
export function reqTenantTree (params) { return request({ url: '/dept/tenant/tree', params, method: 'get' }) }

/* 删除租户 */
export function reqLesseeDelete (params) { return request({ url: '/tenant/:id', params, method: 'delete' }) }

/* 父级租户下拉，通过 parentId 查询子租户，如果不传 parentId，则取顶级租户 */
// export function reqParentLessee () { return request({ url: '/tenant/childs' }) }

/* 关联应用组，获取应用组列表 */
export function reqRelAppGroup (params) { return request({ url: '/appgroup/page', params, method: 'get' }) }

/* 关联应用组，下拉多选保存 */
export function reqRelAppGroupSave (params) { return request({ url: '/tenant/appgroup', params, method: 'PUT' }) }

/* 租户管理的状态 - 获取字典管理列表 */
export function reqDictionary () { return request({ url: '/dictionary/TENANT_STATUS/info' }) }

/* 租户管理 - 根据 id 冻结租户 */
export function reqLockLessee (params) { return request({ url: '/tenant/lock/:id', params, method: 'PUT' }) }

/* 租户管理 - 根据 id 解冻租户 */
export function reqUnlockLessee (params) { return request({ url: '/tenant/unlock/:id', params, method: 'PUT' }) }

// 根据id对租户授权
export function reqLicenseById (id, params) { return request({ url: `/tenant/${id}/license/auth`, params: params, method: 'PUT' }) }
